.App {
  text-align: center;
}

.App-logo {
  height: 28vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-paragraph{
  max-width: 500px;
  font-size: 1rem;
  color: #f5f5f5;
  margin-top: 10px;
  margin-bottom: 10px;
}

.App-address{
  text-align: center;
  font-size: 0.7rem;
  color: #f5f5f5;
  margin-top: 10px;
  margin-bottom: 10px;
}

.App-copyright{
  text-align: center;
  font-size: 0.9rem;
  color: #a6ad9a;
  margin-top: 10px;
  margin-bottom: 10px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
